import { EnvelopeIcon } from '@heroicons/react/24/outline';
import maintenance from './assets/maintenance.jpg';

export default function App() {
  const userLang = navigator.language;

  const translations = {
    en: {
      heading: 'We are trying hard to make things better.',
      contact: 'asiakaspalvelu@maw.fi',
    },
    fi: {
      heading: 'Pyrimme kovasti tekemään asioita paremmaksi.',
      contact: 'asiakaspalvelu@maw.fi',
    },
  };

  const lang = userLang.startsWith('fi') ? 'fi' : 'en';

  return (
    <main
      className='h-screen w-screen'
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${maintenance})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <div className='bg-gray-200 bg-opacity-20 rounded-lg p-10'>
          <h1 className='text-white text-4xl font-bold mb-4'>
            {translations[lang].heading}
          </h1>
          <div className='flex items-center gap-x-4'>
            <EnvelopeIcon
              className='h-10 w-10 text-green-600'
              aria-hidden='true'
            />
            <p className='text-3xl hover:text-green-400 transition-colors'>
              <a
                href='mailto:asiakaspalvelu@maw.fi'
                target='_blank'
                rel='noreferrer'
              >
                {translations[lang].contact}
              </a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
